/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@4.0.0-beta/dist/jquery.min.js
 * - /npm/js-url@2.3.0/url.min.js
 * - /npm/jq-cssvar@1.0.1/jq-cssVar.min.js
 * - /npm/luminosity@1.0.12/dist/luminosity.min.js
 * - /gh/sebascontre/toolkit@0.1.0/jquery.getStylesheet.min.js
 * - /gh/sebascontre/toolkit@0.1.0/jquery.filterByData.min.js
 * - /gh/tmijs/tmi.js@1.9.0-pre.0/dist/tmi.min.js
 * - /npm/@streamerbot/client@1.5.0/dist/streamerbot-client.min.js
 * - /npm/@twemoji/api@15.0.3/dist/twemoji.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
